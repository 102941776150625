import {
    useForm,
    Edit,
    useSelect,
    SaveButton,
    DeleteButton,
    ListButton,
    RefreshButton,
} from "@refinedev/antd";

import { Form, Input, Select, Space, Row, Col, Switch } from "antd";

import {
    ResourceRouterParams,
    useNavigation,
    useParsed,
    useTranslate,
} from "@refinedev/core";

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IUserForUpdate } from "../../../entities/visa/IUserForUpdate";
import { useState } from "react";
import { ConvertStatusID } from "helpers/statusHelper";
import React from "react";
import { IRoleResponse } from "entities/visa/IRoleResponse";
import { css } from 'aphrodite'
import sheet from './style';
import IIncidentCategory from "entities/definition/IIncidentCategory";
import { useConstantValueFromService } from "hooks";
import { IUserForView } from "entities/visa/IUserForView";
import { useFormErrorHandler } from "helpers/serviceResponseHelper";
import IIncidentCause from "entities/definition/IIncidentCause";

declare type ActionParams = {
    action?: "edit" | "create" | "clone";
};

export const IncidentStandardDistributionListEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult, form } = useForm<IUserForUpdate>(
            {
                redirect: false,
                action: parsedAction == "edit" ? "edit" : "create",
                mutationMode: "undoable",
                errorNotification: useFormErrorHandler
            }
        );
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 1;
    const { goBack, list } = useNavigation();


    if (mutationResult.isSuccess) {
        redirect("list");
    }

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }


    const { selectProps: incidentTypeSelectProps } = useConstantValueFromService("GetIncidentTypes")

    const { selectProps: incidentCategorySelectProps, queryResult: incidentCategoryQueryResult } = useSelect<IIncidentCategory>({
        resource: "IncidentCategory",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Name",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps: incidentCauseSelectProps, defaultValueQueryResult: incidentCauseService } = useSelect<IIncidentCause>({
        resource: "IncidentCause",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Name",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        },
        filters: [
            {
                field: "IncidentCategoryID",
                operator: "eq",
                value: form.getFieldValue("incidentCategoryID")
            }
        ],
        queryOptions: {
            enabled: form.getFieldValue("incidentCategoryID") !== undefined
        }
    });

    const { selectProps: userSelectProps } = useSelect<IUserForView>({
        resource: "User",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Username",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });


    return (
        <Edit
            isLoading={formLoading}
            headerButtons={({ defaultButtons }) => (
                <>
                    <ListButton
                        data-testid="edit-list-button"
                        resource={parsedResource?.name}
                        children={translate("pages.IncidentStandardDistributionList.title")}
                    />
                    {parsedAction == "edit" && (
                        <RefreshButton
                            resource={parsedResource?.name}
                            recordItemId={parsedId}
                        />
                    )}
                </>
            )}
            footerButtons={({ defaultButtons }) => (
                <>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                        }}
                    />
                    <SaveButton {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                            redirect("create");
                        }}>
                        {translate("buttons.saveandnew")}
                    </SaveButton>
                    {CheckPermissionsV2("Delete", "Business.Concrete.Definition.IncidentStandardDistributionListManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </>
            )}
            title={translate("buttons.edit")}
            recordItemId={parsedId}
            canDelete={CheckPermissionsV2("Delete", "Business.Concrete.Definition.IncidentStandardDistributionListManager")}>

            <Form {...formProps} layout="vertical" ref={formRef}>
                <Row gutter={24}>
                    <Col span={12} key="incidentCategoryID">
                        <Form.Item label={translate("pages.IncidentStandardDistributionList.fields.incidentcategory")} name="incidentCategoryID">
                            <Select {...incidentCategorySelectProps} onChange={() => {
                                form.setFieldValue("incidentCauseID", undefined)
                                incidentCauseService.refetch()
                            }} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="incidentCauseID">
                        <Form.Item label={translate("pages.IncidentStandardDistributionList.fields.incidentcause")} name="incidentCauseID">
                            <Select {...incidentCauseSelectProps} disabled={form.getFieldValue("incidentCategoryID") === undefined || incidentCauseService.isRefetching} />
                        </Form.Item>
                    </Col>

                    <Col span={12} key="userID">
                        <Form.Item label={translate("pages.IncidentStandardDistributionList.fields.userID")} name="userID">
                            <Select {...userSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="incidentTypeID">
                        <Form.Item label={translate("pages.IncidentStandardDistributionList.fields.incidentTypeID")} name="incidentTypeID" rules={[{ required: false }]}>
                            <Select {...incidentTypeSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="statusID">
                        <Form.Item label={translate("general.status.title")} name="statusID" initialValue={initialStatus}>
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Edit >
    );
};
