import {
    useForm,
    Edit,
    useSelect,
    SaveButton,
    DeleteButton,
    ListButton,
    RefreshButton,
} from "@refinedev/antd";

import { Form, Input, Select, Space, Row, Col, Switch } from "antd";

import {
    ResourceRouterParams,
    useNavigation,
    useParsed,
    useResourceWithRoute,
    useRouterContext,
    useTranslate,
} from "@refinedev/core";

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IUserForUpdate } from "../../../entities/visa/IUserForUpdate";
import { useState } from "react";
import { ConvertBoolean, ConvertStatusID } from "helpers/statusHelper";
import React from "react";
import { IRoleResponse } from "entities/visa/IRoleResponse";
import { css } from 'aphrodite'
import sheet from './style';

declare type ActionParams = {
    action?: "edit" | "create" | "clone";
};

export const UserEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<IUserForUpdate>({ redirect: false, action: parsedAction == "edit" ? "edit" : "create", mutationMode: "undoable" });
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 1;
    var initialLocked = userData?.isLocked ?? false;
    const { goBack, list } = useNavigation();

    const { selectProps, queryResult: SelectQueryResult } = useSelect<IRoleResponse>({
        resource: "Role",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 5000,

        pagination: {
            mode: "server"
        }
    });

    var initialRoles: any[] = [];

    var selectResult = SelectQueryResult?.data?.data;

    Object.assign(initialRoles, (userData?.roleIds ?? []), (selectResult?.filter(op => op.isDefaultRole) ?? []));

    if (mutationResult.isSuccess) {
        redirect("list");
    }

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    return (
        <Edit isLoading={formLoading}
            headerButtons={({ defaultButtons }) => (
                <>
                    <ListButton
                        data-testid="edit-list-button"
                        resource={parsedResource?.name}
                        children={translate("pages.user.title")}
                    />
                    {parsedAction == "edit" && (
                        <RefreshButton
                            resource={parsedResource?.name}
                            recordItemId={parsedId}
                        />
                    )}
                </>
            )}
            footerButtons={({ defaultButtons }) => (
                <>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                        }}
                    />
                    {CheckPermissionsV2("Delete", "Business.Concrete.Visa.UserManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </>
            )}
            title={translate("buttons.edit")} recordItemId={parsedId}>
            <Form {...formProps} layout="vertical" ref={formRef}>
                <Row gutter={24}>
                    <Col span={12} key="Firstname">
                        <Form.Item label={translate("pages.user.fields.firstname")} name="firstname" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Lastname">
                        <Form.Item label={translate("pages.user.fields.lastname")} name="lastname" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={12} key="Username">
                        <Form.Item label={translate("pages.user.fields.username")} name="username" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Email">
                        <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12} key="Password">
                        <Form.Item label={translate("pages.user.fields.password")} name="password">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="roleIds">
                        <Form.Item label={translate("pages.user.fields.roles")} name="roleIds" initialValue={initialRoles}>
                            <Select {...selectProps} mode="multiple" className={css(sheet.permissionList)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12} key="Status">
                        <Form.Item label="Status" name="statusID">
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="isLocked">
                        <Form.Item label={translate("users.fields.isLocked")} name="isLocked" initialValue={initialLocked}>
                            <Switch checkedChildren={ConvertBoolean(true)} unCheckedChildren={ConvertBoolean(false)} defaultChecked={initialLocked}/>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Edit>
    );
};
