import {
    useForm,
    Edit,
    useSelect,
    SaveButton,
    DeleteButton,
    ListButton,
    RefreshButton,
} from "@refinedev/antd";

import { Form, Input, Select, Space, Row, Col, Switch } from "antd";

import {
    ResourceRouterParams,
    useNavigation,
    useParsed,
    useTranslate,
} from "@refinedev/core";

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IUserForUpdate } from "../../../entities/visa/IUserForUpdate";
import { useState } from "react";
import { ConvertStatusID } from "helpers/statusHelper";
import React from "react";
import { IRoleResponse } from "entities/visa/IRoleResponse";
import { css } from 'aphrodite'
import sheet from './style';
import { useFormErrorHandler } from "helpers/serviceResponseHelper";

declare type ActionParams = {
    action?: "edit" | "create" | "clone";
};

export const DistrictEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<IUserForUpdate>(
            {
                redirect: false,
                action: parsedAction == "edit" ? "edit" : "create",
                mutationMode: "undoable",
                errorNotification: useFormErrorHandler
            });
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 1;
    const { goBack, list } = useNavigation();

    var initialRoles: any[] = [];

    if (mutationResult.isSuccess) {
        redirect("list");
    }

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    return (
        <Edit
            isLoading={formLoading}
            headerButtons={({ defaultButtons }) => (
                <>
                    <ListButton
                        data-testid="edit-list-button"
                        resource={parsedResource?.name}
                        children={translate("pages.District.title")}
                    />
                </>
            )}
            footerButtons={({ defaultButtons }) => (
                <>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                        }}
                    />
                    {CheckPermissionsV2("Delete", "Business.Concrete.Definition.DistrictManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </>
            )}
            title={translate("buttons.edit")}
            recordItemId={parsedId}
            canDelete={CheckPermissionsV2("Delete", "Business.Concrete.Definition.DistrictManager")}>

            <Form {...formProps} layout="vertical" ref={formRef}>
                <Row gutter={24}>
                    <Col span={12} key="Name">
                        <Form.Item label={translate("pages.District.fields.name")} name="name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Status">
                        <Form.Item label="Status" name="statusID" initialValue={initialStatus}>
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Edit >
    );
};
