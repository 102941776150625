import {
    List,
    TagField,
    useTable,
    EditButton,
    useEditableTable,
    BooleanField,
    SaveButton,
    useSelect,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Table,
    Menu,
    Grid,
    Form,
    Dropdown,
    Input,
    Switch,
    Space,
    Button,
    Select,
    theme,
} from "antd";

import { IResourceComponentsProps, useNavigation, useTranslate } from "@refinedev/core";
import ITranslationResponse from "../../../entities/configuration/ITranslationResponse";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor, ConvertBoolean, ConvertBooleanToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import React, { useState } from "react";
import ILanguageResponse from "entities/definition/ILanguageResponse";
import { Breadcrumb } from "components/breadcrumb";
import { useDarkMode } from "usehooks-ts";

export const TranslationList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { token } = theme.useToken();

    const [languages, setCurrentLanguages] = useState(
        [{ currentLanguageId: 1, id: 1, name: "Türkçe" }]);

    const [currentLanguageId, setCurrentLanguageId] = useState(1);


    const { tableProps,
        formProps,
        isEditing,
        saveButtonProps,
        cancelButtonProps,
        editButtonProps,
        setId: setEditId,
        queryResult
    } = useEditableTable<ITranslationResponse>({
        meta: {
            variables: {
                queryStringParams: {
                    languageID: currentLanguageId
                }
            }
        }
    });
    const translate = useTranslate();
    const { edit } = useNavigation();

    const moreMenu = (record: ITranslationResponse) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <Icons.FormOutlined
                        style={{
                            color: token.colorPrimary,
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    setEditId?.(record.id.toString());
                }}
            >
                {translate("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="editInPage"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <Icons.FormOutlined
                        style={{
                            color: token.colorPrimary,
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("Translation", record.id)
                }}
            >
                {translate("buttons.editInPage")}
            </Menu.Item>
        </Menu>
    );

    let formRef = React.useRef<any>(null);
    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    const { selectProps } = useSelect<ILanguageResponse>({
        resource: "Language",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,

        onSearch: (value) => [
            {
                field: "name",
                operator: "contains",
                value
            }
        ],

        pagination: {
            mode: "server"
        }
    });

    const onLanguageChange = (value: any, id: number) => {
        setCurrentLanguages(languages);
        setCurrentLanguageId(value);
        queryResult?.refetch();
        var currentLangName = queryResult?.data?.data?.language?.name;
        languages.push({
            currentLanguageId: value,
            id,
            name: currentLangName ?? "Türkçe"
        });
    }
    
    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }

    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)

    return (
        <List title={translate("translation.title")} canCreate={CheckPermissionsV2("Add", "Business.Concrete.Configuration.TranslationManager")} headerProps={{

            breadcrumb: <Breadcrumb />

        }}>
            <Form {...formProps} ref={formRef}>
                <Table {...tableProps} rowKey="id" onRow={(record) => ({
                    onClick: (event: any) => {
                        if (event.target.nodeName === "TD") {
                            setEditId && setEditId(record.id.toString());
                        }
                    },
                })}
                className={isDarkMode ? "table-striped-rows-dark" : "table-striped-rows"}
                bordered
                size="large" >
                    <Table.Column dataIndex="id" title={translate("translation.fields.id")} render={(value, data: ITranslationResponse) => {
                        if (isEditing(data.id.toString())) {
                            return (
                                <Form.Item
                                    name="id"
                                    style={{ margin: 0 }}
                                >
                                    <Input disabled={true} />
                                </Form.Item>
                            );
                        }
                        return value;
                    }} />
                    <Table.Column dataIndex="key" title={translate("translation.fields.key")} render={(value, data: ITranslationResponse) => {
                        if (isEditing(data.id.toString())) {
                            return (
                                <Form.Item
                                    name="key"
                                    style={{ margin: 0 }}
                                >
                                    <Input disabled={true} />
                                </Form.Item>
                            );
                        }
                        return value;
                    }} />
                    <Table.Column dataIndex="value" title={translate("translation.fields.value")} render={(value, data: ITranslationResponse) => {
                        if (isEditing(data.id.toString())) {
                            return (
                                <Form.Item
                                    name="value"
                                    style={{ margin: 0 }}
                                >
                                    <Input />
                                </Form.Item>
                            );
                        }
                        return value;
                    }} />
                    <Table.Column dataIndex="languageId" title={translate("translation.fields.language")} render={(value, data: ITranslationResponse) => {
                        if (isEditing(data.id.toString())) {
                            return (
                                <Form.Item name="languageID" style={{ margin: 0 }}>
                                    <Select {...selectProps} onChange={(e) => onLanguageChange(e, data.id)} />
                                </Form.Item>
                            );
                        }
                        return <TagField value={languages.find(op => op.id == data.id)?.name ?? data.language?.name} />;
                    }} />
                    <Table.Column
                        dataIndex="statusID"
                        title={translate("general.status.title")}
                        render={(value: any, data: ITranslationResponse) => {
                            if (isEditing(data.id.toString())) {
                                return (
                                    <Form.Item name="statusID" style={{ margin: 0 }}>
                                        <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={data.statusID == 1} onChange={onSelectedChange} />
                                    </Form.Item>
                                );
                            }

                            return <TagField value={ConvertStatusID(value)} color={ConvertStatusIDToColor(value)} />
                        }}
                    />
                    <Table.Column<ITranslationResponse>
                        title={translate("general.button.actions")}
                        dataIndex="actions"
                        render={(_text, record) => {
                            if (isEditing(record.id.toString())) {
                                return (
                                    <Space>
                                        <SaveButton
                                            {...saveButtonProps}
                                            size="small"
                                        />
                                        <Button
                                            {...cancelButtonProps}
                                            size="small"
                                        >
                                            {translate("buttons.cancel")}
                                        </Button>
                                    </Space>
                                );
                            }
                            return (
                                <Dropdown
                                    overlay={moreMenu(record)}
                                    trigger={["click"]}
                                >
                                    <Icons.MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            );
                        }}
                    />
                </Table>
            </Form>
        </List>
    );
};

