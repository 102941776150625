import React, { useState } from "react";
import {
    Row,
    Col,
    Layout as AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
    theme
} from "antd";
import { axiosInstance } from "providers/authProvider";
import { useTranslate, useNotification, useCustom, useParsed } from "@refinedev/core";
import { StyleSheet } from 'aphrodite'
import { css } from 'aphrodite'
import { IServiceObjectResult } from "interfaces/Results/ServiceObjectResult";
import logo from "../../assets/images/logo.png";
import { IconMoonStars, IconSun } from "assets/icons/customIcons";
import { Navigate } from "react-router-dom";
import { useDarkMode } from "usehooks-ts";


interface RenewForgottenPasswordProps {
    theme: "light" | "dark";
    setTheme: (theme: "light" | "dark") => void;
}

export interface IRenewForgottenPasswordForm {
    newPassword: string
    token: string
}

const { useToken } = theme;
const { Text, Title } = Typography;

export const RenewForgottenPasswordPage: React.FC<RenewForgottenPasswordProps> = (props) => {

    const { token } = useToken();
    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }
    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)
    const [form] = Form.useForm<IRenewForgottenPasswordForm>();
    const translate = useTranslate();
    const { open, close } = useNotification();

    const sheet = StyleSheet.create({
        layout: {
            background: 'radial-gradient(50% 50% at 50% 50%, ' + token.colorFillSecondary + ' 0%, ' + token.colorPrimary + ' 100%)',
            backgroundRepeat: 'cover'
        },
        layout_container: {
            maxWidth: '408px',
            margin: 'auto'
        },
        layout_title: {
            textAlign: 'center',
            fontSize: '30px',
            letterSpacing: '-0.04em'
        },
        layout_imageContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '16px'
        },
        layout_imageContainer_logo: {
            width: '30vh'
        }
    });

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();
    const [isLoading, setIsLoading] = useState(false);

    const CardTitle = (
        <Title level={3} className={css(sheet.layout_title)}>
            {translate("pages.renewforgottenpassword.title")}
        </Title>
    );

    function renewPassword(form: IRenewForgottenPasswordForm) {
        setIsLoading(true);
        return new Promise((resolve, reject) => {
            axiosInstance.post("forgetpassword/resetpassword", form).then((response) => {
                var serviceResponse: IServiceObjectResult = response.data;
                if (serviceResponse.success) {
                    open?.({
                        message: translate("pages.forgetpassword.successrenew"),
                        type: "success",
                        key: "passwordrenew"
                    });

                    Navigate({
                        to: '/login'
                    });
                }
                else {
                    var message = serviceResponse.messages.at(0)?.description;
                    open?.({
                        message: message ?? translate("pages.forgetpassword.errorrenew"),
                        type: "error",
                        key: "passwordrenew"
                    });
                    reject();
                }
            }).catch((err) => {
                var message = err.response?.data?.messages.at(0)?.description;
                open?.({
                    message: message ?? err.message as string,
                    type: "error",
                    key: "passwordrenew"
                });
                reject(err.message as string);
            }).finally(() => {
                setIsLoading(false);
            });
        });
    }

    return (
        <AntdLayout className={css(sheet.layout)}>
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div className={css(sheet.layout_container)}>
                        <div className={css(sheet.layout_imageContainer)}>
                            <img src={logo} alt="Portal Logo" className={css(sheet.layout_imageContainer_logo)} />
                        </div>
                        <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                            <Form<IRenewForgottenPasswordForm>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    renewPassword(values);
                                }}
                                requiredMark={false}
                                initialValues={{
                                    remember: false,
                                }}
                            >

                                <Form.Item
                                    name="token"
                                    rules={[{ required: true }]}
                                    initialValue={parsedParams?.token}
                                >
                                    <Input hidden />
                                </Form.Item>

                                <Form.Item
                                    name="newPassword"
                                    label={translate("pages.renewforgottenpassword.fields.newPassword")}
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={translate("pages.renewforgottenpassword.fields.newPassword")}
                                    />
                                </Form.Item>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                    loading={isLoading}
                                >
                                    {translate("pages.renewforgottenpassword.fields.renewpassword")}
                                </Button>
                            </Form>
                            <div style={{
                                marginTop: 8,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <Text style={{ fontSize: 12 }}>
                                    {translate("pages.login.accountExist")}{" "}
                                    <a
                                        style={{
                                            fontWeight: 'bold'
                                        }}
                                        href="/login"
                                    >
                                        {translate("pages.login.signin")}
                                    </a>
                                </Text>

                                <Button onClick={() => {
                                    props.setTheme(props.theme === "light" ? "dark" : "light");
                                }}
                                >
                                    {props.theme === "light" ? <IconMoonStars /> : <IconSun />}
                                </Button>
                            </div>
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
}