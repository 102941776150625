import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import { Space, Spin } from 'antd';
import { BrowserRouter } from "react-router-dom";

const AppLoading = () => (
  <div style={
    {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }>
    <Spin tip="Application Loading..." size="large" />
  </div>
);


ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<AppLoading />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
