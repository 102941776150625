import { useTable, ShowButton, TagField } from "@refinedev/antd";
import { Button } from "antd";
import { IResourceComponentsProps, useNotification, useTranslate } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import IMobileAppNotificationLog from "entities/configuration/IMobileAppNotificationLog";
import { DateField } from "components/fields/date";
import { ConvertBooleanToColor } from "helpers/statusHelper";
import { useState } from "react";
import { axiosInstance } from "providers/authProvider";
import { IServiceObjectResult } from "interfaces/Results/ServiceObjectResult";
import { getAllMessages } from "helpers/serviceResponseHelper";
import { RedoOutlined } from "@ant-design/icons";

export const MobileAppNotificationLogList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {

    const { tableProps, tableQueryResult: queryResult, setFilters, setSorters: setSorter } = useTable<IMobileAppNotificationLog>({
        syncWithLocation: true
    });
    const translate = useTranslate();

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.MobileAppNotificationLog.fields.id"),
            type: "number"
        },
        {
            key: "DeviceId",
            title: translate("pages.MobileAppNotificationLog.fields.deviceId"),
            type: "string"
        },
        {
            key: "IsSuccess",
            title: translate("pages.MobileAppNotificationLog.fields.isSuccess"),
            type: "boolean"
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.MobileAppNotificationLog.fields.id")
        },
        {
            dataIndex: "deviceId",
            title: translate("pages.MobileAppNotificationLog.fields.deviceId")
        },
        {
            dataIndex: "title",
            title: translate("pages.MobileAppNotificationLog.fields.title")
        },
        {
            dataIndex: "body",
            title: translate("pages.MobileAppNotificationLog.fields.body")
        },
        {
            dataIndex: ["notificationType", "field"],
            title: translate("pages.MobileAppNotificationLog.fields.notificationType")
        },
        {
            dataIndex: "responseMessage",
            title: translate("pages.MobileAppNotificationLog.fields.responseMessage")
        },
        {
            dataIndex: "isSuccess",
            title: translate("pages.MobileAppNotificationLog.fields.isSuccess"),
            render: (props: RenderComponentProps) => (
                <TagField value={props.value} color={ConvertBooleanToColor(props.value)} />
            )
        },
        /*
        ,
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <ShowButton
                    size="small"
                    recordItemId={props.record.id}
                />
            )
        }*/
    ]
    const [isLoading, setLoading] = useState(false);
    const { open, close } = useNotification();
    const truncateLogs = () => {
        setLoading(true);
        axiosInstance.post("entitylog/truncate").then((response) => {
            var serviceResponse: IServiceObjectResult = response.data;
            var messages = getAllMessages(serviceResponse);

            if (messages.success && messages.success.length > 0) {
                var message = "";

                messages.success.map((item, index) => {

                    if (index == 0) message = item;
                    else message += " | " + item;
                });

                open?.({
                    message: message,
                    type: "success",
                    key: "updateallpermissions"
                });
            }

            if (messages.warning && messages.warning.length > 0) {
                var message = "";

                messages.warning.map((item, index) => {

                    if (index == 0) message = item;
                    else message += " | " + item;
                });

                open?.({
                    message: message,
                    type: "success",
                    key: "updateallpermissions"
                });
            }

            if (messages.error && messages.error.length > 0) {
                var message = "";

                messages.error.map((item, index) => {

                    if (index == 0) message = item;
                    else message += " | " + item;
                });

                open?.({
                    message: message,
                    type: "error",
                    key: "updateallpermissions"
                });
            }


        }).catch((error) => {
            if (error.response) {
                var messages = getAllMessages(error.response);
                if (messages.error && messages.error.length > 0) {
                    var message = "";

                    messages.error.map((item, index) => {

                        if (index == 0) message = item;
                        else message += " | " + item;
                    });

                    open?.({
                        message: message,
                        type: "error",
                        key: "updateallpermissions"
                    });
                }
            }
            else {
                open?.({
                    message: error.message,
                    type: "success",
                    key: "updateallpermissions"
                });
            }
        }).finally(() => {
            queryResult.refetch().finally(() => {
                setLoading(false);
            });
        });
    };

    return (
        <ListPage listProps={{
            title: translate("pages.MobileAppNotificationLog.title"),
            canCreate: false,
            headerButtons: <Button
                onClick={() => truncateLogs()}
                disabled={isLoading}
                icon={<RedoOutlined spin={isLoading} />}
            >
                {translate("pages.EntityLog.truncate")}
            </Button>
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={queryResult.isFetching} />
    );
};