import { HttpError, useLogout, useNavigation, AuthBindings } from "@refinedev/core";
import axios from "axios";
import i18next from '../i18n';
import { IServiceObjectResult } from "../interfaces/Results/ServiceObjectResult";
import { IUserForView } from "../entities/visa/IUserForView";
import { IPermissionResponse } from "../entities/visa/IPermissionResponse";
import { IAuth } from "../entities/IAuth";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { encrypt, decrypt } from "helpers/cryptoHelper";
import { GetServiceUrl } from "helpers/configManager";
import { reject } from "lodash";
import { IServiceResult } from "interfaces/Results/ServiceResult";
import { getAllMessages } from "helpers/serviceResponseHelper";
import { useGetIdentityInterface } from "models/useGetIdentityInterface";
import { IsGlobalAdmin } from "helpers/permissionHelper";

let isPermissionRefreshing: boolean = false;

export const axiosInstance = axios.create({
    baseURL: GetServiceUrl()
});


axiosInstance.interceptors.request.use(function (config) {
    var lang = localStorage.getItem("i18nextLng");
    if (!config) {
        config = {};
    }
    if (!config.headers) {
        config.headers = {};
    }
    if (lang) config.headers["Accept-Language"] = lang;
    return config;
});

axiosInstance.interceptors.request.use((config: any) => {
    const token = getLocalAccessToken();
    if (token) {
        config.headers["Authorization"] = "Bearer " + token;
    }

    const refreshToken = getLocalRefreshToken();
    if (refreshToken) {
        config.headers["X-REFRESH-TOKEN"] = refreshToken;
    }

    return config;
},
    (error) => {
        return Promise.reject(error);
    });


axiosInstance.interceptors.response.use(
    (response) => {
        var newToken = response.headers["x-new-token"];
        var newTokenExpire = response.headers["x-new-token-expire"];
        var newRefreshToken = response.headers["x-new-refreshtoken"];
        var newRefreshTokenExpire = response.headers["x-new-refreshtoken-expire"];
        var temp = localStorage.getItem("auth") ?? "";

        if (temp && newToken) {
            var auth: IAuth = JSON.parse(temp);

            if (auth) {
                if (newRefreshToken && newRefreshTokenExpire) {
                    auth.refreshToken = newRefreshToken;
                    auth.refreshTokenExpiration = new Date(newRefreshTokenExpire);
                    localStorage.setItem("auth", JSON.stringify(auth));
                }

                if (newToken && newTokenExpire) {
                    auth.token = newToken;
                    auth.expiration = new Date(newTokenExpire);

                    localStorage.setItem("auth", JSON.stringify(auth));
                    refreshPermissions();
                }
            }
        }

        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.data?.messages?.at(0)?.description,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);

function getLocalAccessToken() {
    var temp = localStorage.getItem("auth") ?? "";
    var accessToken = ""
    if (temp) {
        var auth: IAuth = JSON.parse(temp);
        if (auth) {
            accessToken = auth.token;
        }
    }

    return accessToken;
}

function getLocalRefreshToken() {
    var temp = localStorage.getItem("auth") ?? "";
    var refreshToken = ""
    if (temp) {
        var auth: IAuth = JSON.parse(temp);
        if (auth && new Date(auth.refreshTokenExpiration) > new Date())
            refreshToken = auth.refreshToken;
    }

    return refreshToken;
}


export function refreshPermissions() {
    return new Promise((resolve, reject) => {
        var lastUpdatedDate = new Date(localStorage.getItem("permission_updateDate")!);
        var now = new Date();
        var difference = Math.abs(now.getTime() - lastUpdatedDate.getTime()) / 6e4; //min

        if (difference > 5) {
            if (!isPermissionRefreshing) {
                isPermissionRefreshing = true;
                axiosInstance.get("Auth/getpermissions").then((response) => {
                    var serviceResponse: IServiceObjectResult = response.data;
                    var permissions: Array<IPermissionResponse> = serviceResponse.data;
                    var encrypted = encrypt(JSON.stringify(permissions));
                    localStorage.setItem("permissions", encrypted);
                    localStorage.setItem("permission_updateDate", new Date().toString());
                }).finally(() => {
                    isPermissionRefreshing = false;
                    resolve(true);
                });
            }
            else {
                resolve(true);
            }
        }
        else {
            resolve(true);
        }
    });

}

function clearLocalStorageForLogout() {
    localStorage.removeItem("auth");
    localStorage.removeItem("remember");
    localStorage.removeItem("permissions");
    localStorage.removeItem("permission_updateDate");
}

function increaseErrorCount() {
    var count = localStorage.getItem("iec_app_lg_ct");

    if (count) {
        localStorage.setItem("iec_app_lg_ct", (parseInt(count) + 1).toString());
    }
    else {
        localStorage.setItem("iec_app_lg_ct", "1");
    }
}

function clearErrorCount() {
    localStorage.removeItem("iec_app_lg_ct");
}

function getErrorCount() {
    return localStorage.getItem("iec_app_lg_ct") ?? "0";
}

function IsTokenValid() {
    var temp = localStorage.getItem("auth") ?? "";

    if (temp) {
        var auth: IAuth = JSON.parse(temp);
        if (auth && new Date(auth.expiration) > new Date()) {
            return true;
        }
    }

    return false;
}

export const authProvider: AuthBindings = {
    login: async ({ username, password, remember, captchaCheckModel, redirectPath = "/" }) => {

        try {
            var response = await axiosInstance.post("Auth/login", { username, password, remindMe: remember, captchaCheckModel }, {
                headers: {
                    "LoginTryCount": getErrorCount()
                }
            });

            var serviceResponse: IServiceObjectResult = response.data;
            var user: IUserForView = serviceResponse.data.user;
            var auth: IAuth = {
                expiration: serviceResponse.data.expiration,
                token: serviceResponse.token,
                refreshToken: serviceResponse.refreshToken,
                refreshTokenExpiration: serviceResponse.data.refreshTokenExpiration,
                user
            };

            localStorage.setItem("auth", JSON.stringify(auth));
            if (remember)
                localStorage.setItem("remember", "true");

            clearErrorCount();

            try {
                await refreshPermissions();
                return {
                    success: true,
                    redirectTo: redirectPath,
                };
            }
            catch (errPerm) {
                clearLocalStorageForLogout();
                return {
                    success: false,
                    error: {
                        message: i18next.t("pages.login.error.name"),
                        name: "Permissions Cannot Receive From System. Please try again Later"
                    }
                };
            }
        }
        catch (error: any) {
            increaseErrorCount();

            if (error.response) {
                var serviceResponse: IServiceObjectResult = error.response.data;

                return {
                    success: false,
                    error: {
                        message: i18next.t("pages.login.error.name"),
                        name: serviceResponse.messages.filter(msg => !msg.isSuccess)[0].description
                    }
                };
            }
            else {
                return {
                    success: false,
                    error: {
                        message: i18next.t("pages.login.error.name"),
                        name: error.message
                    }
                };
            }
        }
    },
    logout: async () => {
        clearLocalStorageForLogout();
        return {
            success: true,
            redirectTo: "/login",
        };
    },
    register: async ({ email, password }) => {
        return {
            success: false,
            error: {
                name: "Register Error",
                message: "Not Implemented"
            },
        };
    },
    forgotPassword: async ({ password }) => {
        // send password reset link to the user's email address here

        // if request is successful
        return {
            success: true,
            redirectTo: "/login",
        };

        // if request is not successful
        return {
            success: false,
            error: {
                name: "Forgot Password Error",
                message: "Email address does not exist",
            },
        };
    },
    updatePassword: async ({ password }) => {
        // update the user's password here

        // if request is successful
        return {
            success: true,
        };

        // if request is not successful
        return {
            success: false,
            error: {
                name: "Update Password Error",
                message: "Email address does not exist",
            },
        };
    },
    check: async () => {
        if (localStorage.getItem("auth")) {
            var temp = localStorage.getItem("auth") ?? "";
            var auth: IAuth = JSON.parse(temp);

            if (new Date(auth.expiration) < new Date()) {
                if (new Date(auth.refreshTokenExpiration) > new Date()) {
                    return {
                        authenticated: true,
                    };
                }
                else {
                    clearLocalStorageForLogout()
                    return {
                        authenticated: false,
                        redirectTo: "/login",
                        logout: true,
                        error: {
                            name: "Check Failed",
                            message: "Token expired."
                        }
                    };
                }
            }
            else {
                return {
                    authenticated: true,
                };
            }
        }
        else {
            return {
                authenticated: false,
                redirectTo: "/login",
                logout: true,
                error: {
                    name: "Check Failed",
                    message: "No token exists."
                }
            };
        }
    },

    onError: async (error) => {
        console.log("onError Func Called - error: ")
        console.log(error)

        if (error) {
            var errorResponseData = error.response?.data;
            if (error.statusCode === 401) {
                return {
                    redirectTo: "/login",
                    logout: true,
                    error: error,
                };
            }
            else if (error.statusCode === 403) {
                if (IsTokenValid()) {
                    if (errorResponseData) {
                        try {
                            var serviceResult = errorResponseData as IServiceResult;
                            var errorMessages = getAllMessages(serviceResult).error;
                            if (errorMessages && errorMessages.length > 0) {
                                var message = errorMessages.at(0);

                                if (message == "Invalid Token")
                                    return {
                                        redirectTo: "/login",
                                        logout: true,
                                        error: error,
                                    };
                                else
                                    return {
                                        redirectTo: "/forbidden",
                                        logout: false,
                                        error: error,
                                    };
                            }
                            else {
                                return {
                                    redirectTo: "/forbidden",
                                    logout: false,
                                    error: error,
                                };
                            }
                        }
                        catch (exc) {
                            return {
                                redirectTo: "/login",
                                logout: true,
                                error: error,
                            };
                        }
                    }
                    else {
                        return {
                            redirectTo: "/forbidden",
                            logout: false,
                            error: error,
                        };
                    }
                }
                else {
                    return {
                        redirectTo: "/login",
                        logout: true,
                        error: error,
                    };
                }
            }
            else {
                return {};
            }
        }
        else {
            return {};
        }
    },

    getPermissions: async () => {
        const encPermissions = localStorage.getItem("permissions") ?? "";
        if (encPermissions && encPermissions != "") {
            var permissions = decrypt(encPermissions);
            return permissions;
        }
        return null;
    },
    getIdentity: async () => {
        const auth = localStorage.getItem("auth") ?? "";
        if (auth && auth != "") {
            const parsedUser: IAuth = JSON.parse(auth);
            const user: useGetIdentityInterface = {
                name: parsedUser.user.firstname + " " + parsedUser.user.lastname,
                avatar: parsedUser.user.Avatar,
                token: parsedUser.token,
                userID: parsedUser.user.id,
                isLocalAdmin: parsedUser.user.isLocalAdmin,
                isGlobalAdmin: parsedUser.user.isGlobalAdmin
                
                
            };

            return user;
        }
        return null;
    }
};
