import i18next from '../i18n';

export function ConvertStatusID (id:number){
    switch(id){
        case 1: return i18next.t("general.status.active");
        case 0: return i18next.t("general.status.passive");
        default: return i18next.t("general.status.unknown");
    }
}

export function ConvertIncidentStatusToText (id:number){
    switch(id){
        case 0: return "Informed";
        case 1: return "Assigned";
        case 2: return "Redirected";
        case 3: return "Completed";
        case 4: return "Closed";
        default: return "Unknown";
    }
}

export function ConvertIncidentStatusToColor (id:number){
    switch(id){
        case 0: return "success";
        case 1: return "warning";
        case 2: return "warning";
        case 3: return "processing";
        case 4: return "success";
        default: return "default";
    }
}

export function ConvertAuditStatusToText (id:number){
    switch(id){
        case 0: return "Detached";
        case 1: return "Unchanged";
        case 2: return "Deleted";
        case 3: return "Modified";
        case 4: return "Added";
        default: return "Unknown";
    }
}

export function ConvertAuditStatusToColor (id:number){
    switch(id){
        case 0: return "processing";
        case 1: return "warning";
        case 2: return "error";
        case 3: return "success";
        case 4: return "success";
        default: return "default";
    }
}

export function ConvertStatusIDToColor (id:number){
    switch(id){
        case 1: return "success";
        case 2: return "error";
        default: return "warning";
    }
}

export function ConvertNotificationStatusIDToColor (id:number){
    switch(id){
        case 1: return "success";
        case 2: return "error";
        default: return "warning";
    }
}

export function ConvertNotificationStatusID (id:number){
    switch(id){
        case 1: return i18next.t("general.notificationstatus.sended");
        case 0: return i18next.t("general.notificationstatus.waiting");
        default: return i18next.t("general.notificationstatus.error");
    }
}

export function ConvertRequestStatusToColor (statusCode:number){
    var statusCodeStr = statusCode.toString();
    var firstNumber = statusCodeStr.at(0);
    switch(Number(firstNumber)){
        case 1: return "processing";
        case 2: return "success";
        case 3: return "warning";
        case 4: return "error";
        case 5: return "error";
        default: return "default";
    }
}

export function ConvertBoolean (value:boolean){
    if(value) return i18next.t("general.status.yes");
    return i18next.t("general.status.no");
}

export function ConvertBooleanToColor (value:boolean){
    if(value) return "success";
    return "error";
}