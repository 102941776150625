import {
    useForm,
    Edit,
    useSelect,
    SaveButton,
    DeleteButton,
    ListButton,
    RefreshButton,
} from "@refinedev/antd";

import { Form, Input, Select, Space, Row, Col, Switch } from "antd";

import {
    ResourceRouterParams,
    useNavigation,
    useParsed,
    useResourceWithRoute,
    useRouterContext,
    useTranslate,
} from "@refinedev/core";

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IPermissionResponse } from "../../../entities/visa/IPermissionResponse";
import { useState } from "react";
import { ConvertStatusID } from "helpers/statusHelper";
import React from "react";

declare type ActionParams = {
    action?: "edit" | "create" | "clone";
};

export const PermissonEdit: React.FC = () => {

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, id, mutationResult } = useForm<IPermissionResponse>({ redirect: false, action: "edit", mutationMode: "undoable" });
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 1;
    const { goBack, list } = useNavigation();

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    if (mutationResult.isSuccess) {
        redirect("list");
    }

    return (
        <Edit isLoading={formLoading}
            headerProps={{
                subTitle: translate("messages.youcanonlychangestatusanddefinition"),
                extra: (<Space wrap>
                    <ListButton
                        data-testid="edit-list-button"
                        resource={parsedResource?.name}
                    />
                    {parsedAction == "edit" && (
                        <RefreshButton
                            resource={parsedResource?.name}
                            recordItemId={parsedId}
                        />
                    )}

                </Space>)
            }}
            headerButtons={
                <Space>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                        }}
                    />
                    {CheckPermissionsV2("Delete", "Business.Concrete.Visa.PermissionManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </Space>
            }
            title={translate("buttons.edit")} recordItemId={parsedId}>
            <Form {...formProps} layout="vertical" ref={formRef}>
                <Form.Item label="Manager" name="manager" >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item label="Method" name="method">
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item label="DefinitionKey" name="definitionKey">
                    <Input />
                </Form.Item>
                <Form.Item label="Status" name="statusID">
                    <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                </Form.Item>
            </Form>
        </Edit>
    );
};
