import { List, TextField, TagField, useTable, ShowButton, EditButton, useSelect } from "@refinedev/antd";
import { Table, Row, Col, Card, Button } from "antd";
import { IResourceComponentsProps, useShow, useTranslate } from "@refinedev/core";
import { usePermissions } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertIncidentStatusToColor, ConvertIncidentStatusToText, ConvertStatusID, ConvertStatusIDToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { useState } from "react";
import { Breadcrumb } from "components/breadcrumb";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { DateField } from "components/fields/date";
import { useConstantValueFromService } from "hooks";
import IDepartment from "entities/definition/IDepartment";
import IStore from "entities/definition/IStore";
import IAuthorizedIncidentOfficer from "entities/definition/IAuthorizedIncidentOfficer";
import IIncidentCategory from "entities/definition/IIncidentCategory";
import IIncidentCause from "entities/definition/IIncidentCause";
import IIncident from "entities/event/IIncident";
import { IUserForView } from "entities/visa/IUserForView";

export const AssignedIncidentsList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult, setFilters, setSorters: setSorter } = useTable<IIncident>({
        meta: {
            controller: "GetAssignedIncidents"
        },
        sorters: {
            initial: [
                {
                    field: "ID",
                    order: "desc"
                }
            ]
        }
    });
    const translate = useTranslate();

    const { selectProps: incidentTypeSelectProps } = useConstantValueFromService("GetIncidentTypes")

    const { selectProps: incidentLocationTypeSelectProps } = useConstantValueFromService("GetIncidentLocationTypes")

    const { selectProps: departmentSelectProps } = useSelect<IDepartment>({
        resource: "Department",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Name",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps: storeSelectProps } = useSelect<IStore>({
        resource: "Store",
        optionLabel: "storeName",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "StoreName",
                operator: "contains",
                value,
            },
            {
                field: "StoreCode",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps: incidentStatusesSelectProps } = useConstantValueFromService("GetIncidentStatuses")



    const { selectProps: incidentCauseSelectProps } = useSelect<IIncidentCause>({
        resource: "IncidentCause",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Name",
                operator: "contains",
                value,
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps: incidentCategorySelectProps } = useSelect<IIncidentCategory>({
        resource: "IncidentCategory",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Name",
                operator: "contains",
                value,
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps: incidentAssignedSelectProps } = useSelect<IUserForView>({
        resource: "User",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Username",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });


    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.Incident.fields.id"),
            type: "number"
        },
        {
            key: "IncidentTypeID",
            title: translate("pages.Incident.fields.incidentTypeID"),
            type: "number",
            selectProps: incidentTypeSelectProps
        },
        {
            key: "IncidentLocationTypeID",
            title: translate("pages.Incident.fields.incidentLocationTypeID"),
            type: "number",
            selectProps: incidentLocationTypeSelectProps
        },
        {
            key: "DepartmentID",
            title: translate("pages.Incident.fields.departmentID"),
            type: "number",
            selectProps: departmentSelectProps
        },
        {
            key: "StoreID",
            title: translate("pages.Incident.fields.storeID"),
            type: "number",
            selectProps: storeSelectProps
        },
        {
            key: "Description",
            title: translate("pages.Incident.fields.description"),
            type: "string"
        },
        {
            key: "IncidentStatusID",
            title: translate("pages.Incident.fields.incidentStatusID"),
            type: "number",
            selectProps: incidentStatusesSelectProps
        },
        {
            key: "IncidentDate",
            title: translate("pages.Incident.fields.incidentDate"),
            type: "date"
        },
        {
            key: "IncidentCategoryID",
            title: translate("pages.Incident.fields.incidentCategoryID"),
            type: "number",
            selectProps: incidentCategorySelectProps
        },
        {
            key: "IncidentCauseID",
            title: translate("pages.Incident.fields.incidentCauseID"),
            type: "number",
            selectProps: incidentCauseSelectProps
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.Incident.fields.id")
        },
        {
            dataIndex: ["incidentType", "name"],
            title: translate("pages.Incident.fields.incidentTypeID")
        },
        {
            dataIndex: ["incidentLocationType", "name"],
            title: translate("pages.Incident.fields.incidentLocationTypeID")
        },
        {
            dataIndex: ["department", "name"],
            title: translate("pages.Incident.fields.departmentID")
        },
        {
            dataIndex: ["store", "storeName"],
            title: translate("pages.Incident.fields.storeID")
        },
        {
            dataIndex: "incidentDate",
            title: translate("pages.Incident.fields.incidentDate")
        },
        {
            dataIndex: ["ownerUser", "label"],
            title: translate("pages.Incident.fields.ownerUser")
        },
        {
            dataIndex: ["incidentCategory", "name"],
            title: translate("pages.Incident.fields.incidentCategoryID")
        },
        {
            dataIndex: ["incidentCause", "name"],
            title: translate("pages.Incident.fields.incidentCauseID")
        },
        {
            dataIndex: "importance",
            title: translate("pages.Incident.fields.importance")
        },
        {
            dataIndex: "incidentStatus",
            title: translate("pages.Incident.fields.incidentStatusID"),
            render: (props: RenderComponentProps) => (
                <TagField value={props.value.name} color={ConvertIncidentStatusToColor(props.value.id)} />
            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <>
                    <ShowButton
                        size="small"
                        recordItemId={props.record.id}
                        hideText
                    />
                </>
            )
        }
    ]

    return (
        <ListPage listProps={{
            title: translate("pages.Incident.title"),
            canCreate: false,
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={tableQueryResult.isFetching}
            standartOnRowRedirectToShow={true} />
    );
};