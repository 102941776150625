import React from "react";

import { useLink, TitleProps } from "@refinedev/core";

import logo from "../../../assets/images/logo.png";
import logo_light from "../../../assets/images/logo_light.png";
import logoCollapsed from "../../../assets/images/logo-collapsed.png";
import logoCollapsed_light from "../../../assets/images/logo-collapsed_light.png";
import { css, CSSProperties, StyleDeclarationMap } from "aphrodite";
import { useDarkMode } from "usehooks-ts";

interface CustomTitleProps extends TitleProps {
    theme: "light" | "dark";
    collapsed: boolean,
    classes?: any[]
}

export const ApplicationTitle: React.FC<CustomTitleProps> = ({ collapsed, classes }) => {
    const Link = useLink();

    var initialDarkMode: boolean = false;
    var localStorageTheme = localStorage.getItem("usehooks-ts-dark-mode");

    if (localStorageTheme === "true") {
        initialDarkMode = true;
    }
    const { isDarkMode, toggle, enable, disable } = useDarkMode(initialDarkMode)

    if (!classes) classes = [];

    return (
        <Link to="/" href="/">
            {collapsed ? (
                <img
                    src={!isDarkMode ? logoCollapsed_light : logoCollapsed}
                    alt="Appouse"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "12px 24px",
                    }}
                    className={css(classes)}
                />
            ) : (
                <img
                    src={!isDarkMode ? logo_light : logo}
                    alt="Appouse"
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        verticalAlign: "middle"
                    }}
                    className={css(classes)}
                />
            )}
        </Link>
    );
};
