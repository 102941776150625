import axios, { AxiosInstance } from "axios";
import { stringify } from "query-string";
import { Logics, Operations } from '../enums/enums';
import {
    DataProvider,
    HttpError,
    CrudOperators,
    CrudFilters,
    CrudSorting,
    Pagination,
    LogicalFilter,
} from "@refinedev/core";
import { FilterItem, FilterQueryLanguage } from "libs/filter-query-language";
import { isNullOrWhitespace } from "helpers/stringHelper";
import { getFormData } from "helpers/objectHelper";

const formDataConfig = {
    headers: {
        "Content-Type": "multipart/form-data"
    }
};

const mapOperator = (operator: CrudOperators): Operations => {
    switch (operator) {

        case "ne":
            return Operations.NEQ;
        case "lt":
            return Operations.LT;
        case "gt":
            return Operations.GT;
        case "lte":
            return Operations.LTE;
        case "gte":
            return Operations.GTE;
        case "contains":
            return Operations.CONTAINS;
        case "ncontains":
            return Operations.NCONTAINS;
        case "startswith":
            return Operations.STARTS;
        case "endswith":
            return Operations.ENDS;
        case "eq":
            return Operations.EQ;
        default:
            throw "UNSUPPORTED OPERATION"
    }
};


const generateSort = (sort?: CrudSorting) => {
    if (sort && sort.length > 0) {
        var model = [];


        const _sort: string[] = [];
        const _order: string[] = [];

        sort.map((item) => {
            _sort.push(item.field);
            _order.push(item.order);
        });

        return {
            _sort,
            _order,
        };
    }

    return;
};


const generateFilter = (filters?: CrudFilters): FilterQueryLanguage => {
    var fql: FilterQueryLanguage = {
        logic: Logics.AND,
        filterQueries: []
    };

    var fql2 = {} as FilterQueryLanguage;
    fql2.filterQueries = [];


    if (filters) {
        filters.map((filter, index) => {

            if (filter.operator !== "or") {
                if (index == 0)
                    fql2.logic = Logics.AND;
                const { field, operator, value } = filter as LogicalFilter;
                fql2.filterQueries.push({
                    logic: Logics.AND,
                    field,
                    filterItems: [{
                        value,
                        operation: mapOperator(operator)
                    }]
                });
            } else {
                if (index == 0)
                    fql2.logic = Logics.OR;
                const filterItemGrouped: { [key: string]: FilterItem[] } = {};
                const { operator, value } = filter;

                value.map((filter) => {
                    const { field, operator, value } = filter as LogicalFilter;

                    if (filterItemGrouped[field]) {
                        filterItemGrouped[field].push({
                            operation: mapOperator(operator),
                            value
                        });
                    }
                    else {
                        filterItemGrouped[field] = [{
                            operation: mapOperator(operator),
                            value
                        }];
                    }
                });

                for (let key in filterItemGrouped) {
                    let filterItems = filterItemGrouped[key];
                    fql2.filterQueries.push({
                        logic: Logics.OR,
                        field: key,
                        filterItems
                    });
                }
            }

            if (filter.operator !== "or") {
                const { field, operator, value } = filter as LogicalFilter;
                fql.filterQueries.push({
                    logic: Logics.AND,
                    field,
                    filterItems: [{
                        value,
                        operation: mapOperator(operator)
                    }]
                });

            }
            else {
                const filterItemGrouped: { [key: string]: FilterItem[] } = {};
                const { operator, value } = filter;

                value.map((filter) => {
                    const { field, operator, value } = filter as LogicalFilter;

                    if (filterItemGrouped[field]) {
                        filterItemGrouped[field].push({
                            operation: mapOperator(operator),
                            value
                        });
                    }
                    else {
                        filterItemGrouped[field] = [{
                            operation: mapOperator(operator),
                            value
                        }];
                    }


                });

                for (let key in filterItemGrouped) {
                    let filterItems = filterItemGrouped[key];
                    fql.filterQueries.push({
                        logic: Logics.OR,
                        field: key,
                        filterItems
                    });
                }
            }
        });
    }

    return fql2;
};

const generateQueryModel = (filters?: CrudFilters, sort?: CrudSorting, pagination?: Pagination) => {
    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;
    let model = {
        page: current,
        limit: pageSize
    };

    Object.assign(model, { Sort: sort }, { Filter: generateFilter(filters) });
    return model;
}

export const JsonServer = (
    apiUrl: string,
    httpClient: AxiosInstance,
): DataProvider => ({
    getList: async ({ resource, pagination, filters, sorters, meta }) => {
        let url = `${apiUrl}/${resource}/getall`;

        if(meta && meta.controller){
            url = `${apiUrl}/${resource}/${meta.controller}`;
        }
        
        if (meta && meta.endpoint) {
            url = meta.endpoint
        }

        let _data: any;
        let _headers: any;
        let _dataFieldName: string = "";
        if (meta && meta.dataFieldName) _dataFieldName = meta.dataFieldName;

        if (meta && meta.method == "GET") {
            const { data, headers } = await httpClient.get(
                `${url}`
            );

            _data = data;
            _headers = headers;
        }
        else {
            var model = generateQueryModel(filters, sorters, pagination);

            const { data: data, headers } = await httpClient.post(
                `${url}`, model
            );

            _data = data;
            _headers = headers;
        }

        let total = 0;

        if (_dataFieldName != "") {
            total = _data.data[_dataFieldName].length;
            return {
                data: _data.data[_dataFieldName],
                total,
            };
        }
        else {
            total = _data.totalDataCount;
            return {
                data: _data.data,
                total,
            };
        }
    },

    getMany: async ({ resource, ids }) => {
        const { data } = await httpClient.get(
            `${apiUrl}/${resource}/getMany?${stringify({ id: ids })}`,
        );

        return {
            data: data.data,
        };
    },

    create: async ({ resource, variables, meta }) => {
        const urlForm = `${apiUrl}/${resource}/updateFromForm`;
        const urlBody = `${apiUrl}/${resource}/updateFromBody`;
        var isFormRequest = meta?.isFormRequest ?? false;

        let _data: any;

        if (isFormRequest) {
            var form_data = getFormData(variables);
            const { data } = await httpClient.post(urlForm, form_data, formDataConfig);
            _data = data;
        }
        else {
            const { data } = await httpClient.post(urlBody, variables);
            _data = data;
        }

        return {
            data: _data.data,
        };
    },

    createMany: async ({ resource, variables, meta }) => {
        var isFormRequest = meta?.isFormRequest ?? false;
        const urlForm = `${apiUrl}/${resource}/updateFromForm`;
        const urlBody = `${apiUrl}/${resource}/updateFromBody`;

        const response = await Promise.all(
            variables.map(async (param) => {
                let _data: any;

                if (isFormRequest) {
                    var form_data = getFormData(param);
                    const { data } = await httpClient.post(
                        urlForm,
                        form_data,
                        formDataConfig
                    );
                    _data = data;
                }
                else {
                    const { data } = await httpClient.post(
                        urlBody,
                        param
                    );
                    _data = data;
                }

                return _data;
            }),
        );

        return { data: response };
    },

    update: async ({ resource, id, variables, meta }) => {
        const urlForm = `${apiUrl}/${resource}/updateFromForm`;
        const urlBody = `${apiUrl}/${resource}/updateFromBody`;
        var isFormRequest = meta?.isFormRequest ?? false;


        let _data: any;

        if (isFormRequest) {
            var form_data = getFormData(variables);
            const { data } = await httpClient.post(urlForm, form_data, formDataConfig);
            _data = data;
        }
        else {
            const { data } = await httpClient.post(urlBody, variables);
            _data = data;
        }



        return {
            data: _data.data,
        };
    },

    updateMany: async ({ resource, ids, variables }) => {
        const urlForm = `${apiUrl}/${resource}/updateFromForm`;
        const urlBody = `${apiUrl}/${resource}/updateFromBody`;
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.post(
                    urlBody,
                    variables,
                    formDataConfig
                );
                return data;
            }),
        );

        return { data: response };
    },

    getOne: async ({ resource, id, meta }) => {

        let variables: any = meta?.variables;
        let url = `${apiUrl}/${resource}/getbyid?id=${id}`;

        if (variables && variables.hasOwnProperty("queryStringParams")) {
            var customQuery = stringify(variables["queryStringParams"])
            if (!isNullOrWhitespace(customQuery)) {
                url += `&${customQuery}`;
            }
        }

        const { data } = await httpClient.get(url);
        return {
            data: data.data,
        };
    },

    deleteOne: async ({ resource, id }) => {
        const url = `${apiUrl}/${resource}/delete?id=${id}`;
        const { data } = await httpClient.post(url);
        return {
            data: data.data,
        };
    },

    deleteMany: async ({ resource, ids }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.post(
                    `${apiUrl}/${resource}/delete?id=${id}`,
                );
                return data;
            }),
        );
        return { data: response };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({ url, method, filters, sorters, payload, query, headers }) => {

        let requestUrl = `${url}`;

        if (url.includes("?"))
            requestUrl = `${requestUrl}&`;
        else
            requestUrl = `${requestUrl}?`;


        if (sorters) {
            const generatedSort = generateSort(sorters);
            if (generatedSort) {
                const { _sort, _order } = generatedSort;
                const sortQuery = {
                    _sort: _sort.join(","),
                    _order: _order.join(","),
                };
                requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
            }
        }


        if (filters) {
            const filterQuery = generateFilter(filters);
            requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
        }

        if (query) {
            requestUrl = `${requestUrl}&${stringify(query)}`;
        }

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers,
            };
        }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(url);
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});

export default JsonServer;
