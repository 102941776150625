import {
    useForm,
    Edit,
    useSelect,
    SaveButton,
    DeleteButton,
    useCheckboxGroup,
    ListButton,
    RefreshButton,
} from "@refinedev/antd";

import { Form, Input, Select, Space, Row, Col, Switch, Checkbox } from "antd";

import {
    ResourceRouterParams,
    useNavigation,
    useParsed,
    useResourceWithRoute,
    useRouterContext,
    useTranslate,
} from "@refinedev/core";

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IClientRoleResponse } from "../../../entities/visa/IClientRoleResponse";
import { useState } from "react";
import { ConvertStatusID,ConvertBoolean } from "helpers/statusHelper";
import React from "react";
import { IPermissionResponse } from "entities/visa/IPermissionResponse";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { css } from 'aphrodite'
import sheet from './style';



export const ClientRoleEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();
    
    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect,formLoading,mutationResult } = useForm<IClientRoleResponse>({ redirect: false,action: parsedAction == "create" ? "create":"edit",mutationMode:"undoable" });
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 0;
    var initialDefaultStatus = userData?.isDefaultRole ?? false;
    var initialPermissions = userData?.permissionIds ?? [];
    const { goBack, list } = useNavigation();

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked:boolean) => {
        formRef.current.setFieldsValue({
            statusID:checked ? 1 : 0
        });
    }

    const { selectProps } = useSelect<IPermissionResponse>({
        resource: "Permission",
        optionLabel:"definitionKey",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,

        onSearch: (value) => [
            {
                field:"DefinitionKey",
                operator:"contains",
                value
            }
        ],

        pagination: {
            mode: "server"
        }
    });

    if(mutationResult.isSuccess){
        redirect("list");
    }

    return (
        <Edit isLoading={formLoading}
        headerProps={{
            extra:(<Space wrap>
                <ListButton
                        data-testid="edit-list-button"
                        resource={parsedResource?.name}
                    />
                {parsedAction == "edit" && (
                    <RefreshButton
                    resource={parsedResource?.name}
                    recordItemId={parsedId}
                />
                )}
                
            </Space>)
        }} 
            headerButtons={
                <Space>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                        }}
                    />
                    { CheckPermissionsV2("Delete","Business.Concrete.Visa.ClientRoleManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </Space>
            }
         title={translate("buttons.edit")} recordItemId={parsedId}>
            <Form {...formProps} layout="vertical" ref={formRef}>
                <Row gutter={24}>
                    <Col span={24} key="Name">
                        <Form.Item label={translate("clientrole.fields.name")} name="name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12} key="isDefaultClientRole">
                        <Form.Item label={translate("clientrole.fields.isDefaultRole")} name="isDefaultClientRole">
                            <Switch checkedChildren={ConvertBoolean(true)} unCheckedChildren={ConvertBoolean(false)} defaultChecked={initialDefaultStatus}/> 
                        </Form.Item>
                    </Col>
                    <Col span={12} key="Status">
                        <Form.Item label={translate("clientrole.fields.status")} name="statusID">
                            <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange}/>                            
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} key="PermissionIds">
                        <Form.Item label={translate("clientrole.fields.permissions")} name="PermissionIds" initialValue={initialPermissions}>      
                                <Select {...selectProps} mode="multiple" showSearch={true} className={css(sheet.permissionList)}/>
                        </Form.Item>
                    </Col>
                    
                </Row>
                
            </Form>
        </Edit>
    );
};
