import React, { useEffect, useState } from "react";
import { useCustom, useApiUrl, BaseKey, useList, CrudFilters } from "@refinedev/core";
import IEnumModel from "entities/constant/IEnumModel";
import { IPermissionResponse } from "entities/visa/IPermissionResponse";
import { useSelect } from "@refinedev/antd";
import { IPermissionGrouped } from "entities/visa/IPermissionGrouped";
import { groupByToMap } from "helpers/arrayHelper";
import { debounce } from "lodash";

export type PermissionGroupProps = {
    onSearch?: (value: string) => CrudFilters,
    debounce?: number
}

const defaultValues = {
    onSearch: undefined,
    debounce: 300
}

export const usePermissionGroup = (props?: PermissionGroupProps) => {
    var _props: PermissionGroupProps = {
        ...props,
        ...defaultValues
    }
    const [search, setSearch] = useState<CrudFilters>([]);
    const postListQueryResult = useList<IPermissionResponse>({
        resource: "Permission",

        pagination: {
            current: 1,
            pageSize: 10000
        },

        filters: search,

        sorters: [
            {
                order: "asc",
                field: "manager"
            }
        ]
    });

    const onSearch = (value: string) => {
        if (_props.onSearch) {
            setSearch(_props.onSearch(value));
            return;
        }

        if (!value) {
            setSearch([]);
            return;
        } else {
            setSearch([
                {
                    field: "DefinitionKey",
                    operator: "contains",
                    value,
                },
            ]);
        }
    };

    var permissions: IPermissionGrouped[] = []

    var data = postListQueryResult.data?.data;
    var grouped = groupByToMap(data ?? [], (value: IPermissionResponse) => {
        return value.manager;
    });

    grouped.forEach((value: IPermissionResponse[], key: string) => {
        var data = grouped.get(key);
        if (data && data.length > 0) {
            permissions.push({
                manager: key,
                permissions: data
            });
        }
    });

    return {
        permissions,
        onSearch: debounce(onSearch, _props.debounce),
        loading: postListQueryResult.isLoading
    };
};