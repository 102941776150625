import { List, TextField, TagField, useTable, ShowButton, EditButton, useSelect } from "@refinedev/antd";
import { Table, Row, Col, Card } from "antd";
import { IResourceComponentsProps, useShow, useTranslate } from "@refinedev/core";
import { IUserForView } from "../../../entities/visa/IUserForView";
import { usePermissions } from "@refinedev/core";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { ConvertStatusID, ConvertStatusIDToColor } from "helpers/statusHelper";
import { CheckPermissionsV2 } from "helpers/permissionHelper";
import { useState } from "react";
import { Breadcrumb } from "components/breadcrumb";
import { ColumnFilterInfo, ColumnInfo, ListPage, RenderComponentProps } from "components/page/list/ListPage";
import { DateField } from "components/fields/date";
import { useConstantValueFromService } from "hooks";

export const AuthorizedIncidentOfficerList: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const { tableProps, tableQueryResult, setFilters, setSorters: setSorter } = useTable<IUserForView>();
    const translate = useTranslate();

    const { selectProps: incidentTypeSelectProps } = useConstantValueFromService("GetIncidentTypes")

    const { selectProps: userSelectProps } = useSelect<IUserForView>({
        resource: "User",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Username",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    let filters: Array<ColumnFilterInfo> = [
        {
            key: "ID",
            title: translate("pages.authorizedincidentofficer.fields.id"),
            type: "number"
        },
        {
            key: "UserID",
            title: translate("pages.authorizedincidentofficer.fields.userID"),
            type: "number",
            selectProps: userSelectProps
        },
        {
            key: "IncidentTypeID",
            title: translate("pages.authorizedincidentofficer.fields.incidentTypeID"),
            type: "number",
            selectProps: incidentTypeSelectProps
        }
    ];

    let columns: Array<ColumnInfo> = [
        {
            dataIndex: "id",
            title: translate("pages.authorizedincidentofficer.fields.id")
        },
        {
            dataIndex: ["incidentType", "name"],
            title: translate("pages.authorizedincidentofficer.fields.incidentTypeID")
        },
        {
            dataIndex: ["user", "label"],
            title: translate("pages.authorizedincidentofficer.fields.userID")
        },
        {
            dataIndex: "statusID",
            title: translate("general.status.title"),
            render: (props: RenderComponentProps) => (
                <TagField value={ConvertStatusID(props.value)} color={ConvertStatusIDToColor(props.value)} />
            )
        },
        {
            dataIndex: "actions",
            title: translate("general.button.actions"),
            render: (props: RenderComponentProps) => (
                <EditButton
                    size="small"
                    recordItemId={props.record.id}
                    value={translate("general.button.show")}
                />
            )
        }
    ]

    return (
        <ListPage listProps={{
            title: translate("pages.authorizedincidentofficer.title"),
            canCreate: CheckPermissionsV2("Add", "Business.Concrete.Event.AuthorizedIncidentOfficerManager"),
        }} tableProps={tableProps} columns={columns} filters={filters} rowKey="id" setFilters={setFilters} setSorter={setSorter} isLoading={tableQueryResult.isFetching} />
    );
};