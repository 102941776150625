import React, { useEffect, useState } from "react";
import { ResourceErrorRouterParams, useParsed } from "@refinedev/core";
import { Button, Result, Typography, Space, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigation, useTranslate, useResourceWithRoute, useRouterContext } from "@refinedev/core";
import { ResultStatusType } from "antd/lib/result";

const { Text } = Typography;


declare type ErrorPageProps = {
    status?: ResultStatusType | undefined
};

const defaultValues = {
    status: ("404" as ResultStatusType)
}

export const ErrorComponent: React.FC<ErrorPageProps> = (props) => {
    var _props: ErrorPageProps = {
        ...defaultValues,
        ...props,
    }

    const [errorMessage, setErrorMessage] = useState<string>();
    const { push } = useNavigation();
    const translate = useTranslate();
    const actionTypes = ["edit", "create", "show"];

    const { useParams } = useRouterContext();

    const params = useParsed();

    useEffect(() => {
        if (params.resource) {

            if (
                params.action &&
                actionTypes.includes(params.action) &&
                !params.resource[params.action]
            ) {
                setErrorMessage(
                    translate(
                        "pages.error.info",
                        {
                            action: params.action,
                            resource: params.resource,
                        },
                        `You may have forgotten to add the "${params.action}" component to "${params.resource}" resource.`,
                    ),
                );
            }
        }
    }, [params]);


    const GetErrorMessage = (status: ResultStatusType | undefined) => {
        if (!status) status = "404";

        if (status == "404")
            return translate(
                "pages.error.404",
                "Sorry, the page you visited does not exist.",
            )

        else if (status == "403")
            return translate(
                "pages.error.403",
                "Sorry, you do not have permission to access the page you visited.",
            )
    }

    return (
        <Result
            status={_props.status}
            title={_props.status}
            extra={
                <Space direction="vertical" size="large">
                    <Space>
                        <Text>
                            {GetErrorMessage(_props.status)}
                        </Text>
                        {errorMessage && (
                            <Tooltip title={errorMessage}>
                                <InfoCircleOutlined data-testid="error-component-tooltip" />
                            </Tooltip>
                        )}
                    </Space>
                    <Button type="primary" onClick={() => push("/")}>
                        {translate("pages.error.backHome", "Back Home")}
                    </Button>
                </Space>
            }
        />
    );
};



